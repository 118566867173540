/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.orders').controller('ShowNotesDialogController', ['$state', '$mdDialog', 'OrderHoldReasonResourceFactory', 'SpecialOrderTypeResourceFactory', 'SecurityAccountResourceFactory', 'UserResourceFactory', 'loginService', 'order', function ($state, $mdDialog, OrderHoldReasonResourceFactory, SpecialOrderTypeResourceFactory, SecurityAccountResourceFactory, UserResourceFactory, loginService, order) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    var sessionData = loginService.getSessionData();
    /* BINDABLE : DATA */

    vm.users = null;
    vm.order = null;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.displaySubmittedTime = displaySubmittedTime;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save() {
      vm.order.holdReasonTypeId = vm.order.holdReasonTypeId === '' ? null : vm.order.holdReasonTypeId;
      vm.order.specialOrderTypeId = vm.order.specialOrderTypeId === '' ? null : vm.order.specialOrderTypeId;
      vm.order.requestedByUserId = vm.order.requestedByUserId === '' ? null : vm.order.requestedByUserId;
      $mdDialog.hide(vm.order);
    }

    function displaySubmittedTime(time) {
      return time ? moment.tz(time, 'YYYY-MM-DDTHH:mm:ss:SSS-ZZ', moment.tz.guess()).format('MM/DD/YY h:mm:ss A z') : '';
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      OrderHoldReasonResourceFactory.getAll({
        id: null
      }, function success(orderHoldReasons) {
        vm.orderHoldReasons = orderHoldReasons.data;
      });
      SpecialOrderTypeResourceFactory.getAll({
        id: null
      }, function success(specialOrderTypes) {
        vm.specialOrderTypes = specialOrderTypes.data;
      });
      UserResourceFactory.getAll({
        accountId: sessionData.accountId
      }, function success(users) {
        vm.users = users.data.rows;
      });

      if (loginService.isAdmin()) {
        SecurityAccountResourceFactory.getAccountNotes({
          id: sessionData.accountId
        }, function success(response) {
          vm.accountNotes = response.data.notes;
        });
      }

      vm.order = order;
    }
  }]);
})();